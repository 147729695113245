import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div
      style={{
        textAlign: "center",
        minHeight: "18rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "18px",
      }}
    >
      <h1>404: Not Found</h1>
      <p>
        You just hit a url that doesn&#39;t exist. Click{" "}
        <Link to="/">
          <span style={{ textDecoration: "underline", color: "#323389" }}>
            here
          </span>
        </Link>{" "}
        to get back to the homepage.
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
